import {
  AGENCY,
  Carrier,
  CarrierOTCSummaryData,
  CarrierResponse,
  CarriersOTCSummariesParams,
  CarrierV2Params,
} from './types'
export * from './types'

export const getCarriers = async (stateCode: string): Promise<Carrier[]> => {
  const { data } = await axios.get<any>(
    import.meta.env.VITE_WEBSITE_API_URL + `/enrollments?state=${stateCode}`
  )

  const formattedCarriers: Array<Carrier> = data.map(
    (carrier: CarrierResponse) => {
      return {
        id: carrier.id,
        name: carrier.carrier,
        enrollments: new Intl.NumberFormat('en-US').format(carrier.enrollments),
        change: carrier.percent_change_last_year,
        liked: false,
      }
    }
  )

  return formattedCarriers
}

export const getCarriersOTCSummaries = async ({
  stateCode,
  zipCode,
  carriers,
  planTypes,
}: CarriersOTCSummariesParams): Promise<CarrierOTCSummaryData[]> => {
  let url = import.meta.env.VITE_WEBSITE_API_URL + `/carriers/otc_summary`
  let params = new URLSearchParams()
  if (stateCode) {
    params.append('state', stateCode)
  }
  if (zipCode) {
    params.append('zip_code', zipCode)
  }
  if (carriers && carriers.length > 0) {
    carriers.forEach((carrier) => {
      params.append('carriers_groups[]', carrier)
    })
  }
  if (planTypes && planTypes.length > 0) {
    planTypes.forEach((plan) => {
      params.append('plan_types[]', plan)
    })
  }

  const finalUrl = url + '?' + params.toString()

  const { data } = await axios.get<any>(finalUrl)
  return data
}

export const getCarriersV2 = async (
  carrierParams: CarrierV2Params
): Promise<string[]> => {
  const params = new URLSearchParams()
  if (carrierParams.agency) {
    params.append('agency', carrierParams.agency)
  } else {
    params.append('agency', AGENCY.MPA)
  }
  if (carrierParams.benefits) {
    carrierParams.benefits.forEach((benefit) => {
      params.append('benefits[]', benefit)
    })
  }

  if (carrierParams.zip_code) {
    params.append('zip_code', carrierParams.zip_code)
  }
  if (carrierParams.state) {
    params.append('state', carrierParams.state)
  }
  if (carrierParams.fips) {
    params.append('fips', carrierParams.fips)
  }
  const { data } = await axios.get<any>(
    import.meta.env.VITE_WEBSITE_API_URL + `/carriers/v2?${params.toString()}`
  )

  const formattedCarriers: Array<string> = data.map(
    (carrier: CarrierResponse) => {
      return carrier.carrier === 'United'
        ? 'UnitedHealthcare®'
        : carrier.carrier
    }
  )

  return formattedCarriers
}
